.booking-status-component-failed {
    background: #de350b;
}

.booking-status-component-success {
    background: #00875a;
}

.booking-status-component-message {
    width: 400px;
    height: fit-content;
    text-align: left;
    margin-top: 10px;
    padding-left: 10px;
    padding-right:10px;
    padding-bottom:10px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px;
}

.booking-status-component-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
}

.booking-status-component-title-left {
    width: 100%;
}

.booking-status-component-title-right {
    margin-top: 5px;
}