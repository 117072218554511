.booking-header {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #191c24;
    color: #646b8a;
}

.booking-header-left {
    width: 100%;
}
.booking-header-right {
    gap: 10px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.booking-content{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking-picker{
    width: 400px;
    align-items: center;
}

.booking-calendar-wrapper{
    background: #191c24;
    color: #6c7293;
    padding:25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.booking-divider {
    height: 1px;
    width: 400px;
    background: #646b8a;
}

.booking-button{
    text-align: center;
    align-items: center;
    width: 400px;
    height: 40px;
    line-height: 40px;
    background: #191c24;
    color: #646b8a;
    font-size: large;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.booking-button:hover{
    background: #0f1015;
}


