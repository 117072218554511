.auth-component {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-button{
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.icon-button:hover{
    background: #0f1015;
}


